export default {
  basicViewItem: [{
    prop: 'name',
    label: '',
    Object: 'value'
  }, {
    prop: 'wechat_id',
    label: '',
    Object: 'value'
  }, {
    prop: 'whatsapp_number',
    label: '',
    Object: 'value'
  }, {
    prop: 'phone_number',
    label: '',
    Object: 'value'
  }, {
    prop: 'email',
    label: '',
    Object: 'value'
  }, {
    prop: 'icon',
    span: 18,
    image: true
  }]
}
