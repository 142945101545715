import { getSuppliersDetails, deleteOnlineCouponDetails } from '@/services/suppliers.js'
import viewFrame from '@/components/viewFrame'
import history from '@/common/data/jsonData'
import { getHistoryList } from '@/services/resource'
import createTable from '@/components/createTable'
import customeTable from '@/components/customeTable'
import dialogEffect from '@/components/dialogEffect'
import jsonData from './jsonData'
import loading from '@/utils/loading.json'
import Utils from '@/utils/Utils.js'
import { mixin } from '@/mixins/viewDiolog.js'
export default {
  components: {
    createTable,
    viewFrame,
    customeTable,
    dialogEffect
  },
  mixins: [mixin],
  data () {
    return {
      urlParams: null,
      validateVisible: false,
      buttonObj: [],
      titleObject: {},
      custName: {},
      formData: {},
      modifyData: {},
      type: '',
      basicViewItem: jsonData.basicViewItem,
      historyTableItem: history.historyTableItem,
      dialogData: [],
      batchData: [],
      dialogVisible: false,
      historyTableData: []
    }
  },
  created () {
    const _this = this
    _this.urlParams = _this.$route.params
  },
  mounted () {
    const _this = this
    _this.init()
  },
  methods: {
    setGlobalLoading (show) {
      const _this = this
      show ? _this.$store.state.app.loading = _this.$loading(loading) : _this.$store.state.app.loading.close()
    },
    // 初始化
    async init () {
      const _this = this
      Object.assign(this.titleObject, { title: `${_this.$t('menu.suppliers')} `, data: _this.urlParams.id })
      _this.setGlobalLoading(true)
	  const res = await getSuppliersDetails(_this.urlParams.id)
	  if (res.status === 200) {
		  _this.$message({
		    type: 'success',
		    message: _this.$t('message.loadSuccess')
		  })
		  const obj = { name: '', value: [] }
		  const { images } = res.data.supplier.basic_information
		  if (images && images.length > 0) {
		    images.forEach(res => {
		      obj.value.push({ value: res.image.value })
		    })
		    obj.name = images[0].image.name
		    res.data.supplier.basic_information.images = obj
		  }
		  console.log(res.data.supplier.basic_information)
		  this.$set(this, 'custName', res.data.supplier)
	  }
	  _this.setGlobalLoading(false)
      //  获取历史操作
      getHistoryList({ item_type: 'Supplier', item_id: _this.urlParams.id }).then(res => {
        if (res.status === 200) {
          this.historyTableData = res.data.versions
        }
      })
    },
    handleBasicInfoForwardToPage (data, name) {
      if (name === 'shop_item') {
        this.$router.push({
          name: 'suppliersDetail',
          params: {
            id: this.urlParams.id
          }
        })
      } else if (name === 'shop_item_stock') {
        this.$router.push({
          name: 'suppliersDetail',
          params: {
            id: this.urlParams.id
          }
        })
      }
    },
    async handleClick (type, data) {
      console.log(type, data)
      if (type === 'create') {
        this.$router.push({
          name: 'suppliersAdd'
        })
      }
      if (type?.action === 'view') {
        this.$router.push({
          name: 'suppliersDetail',
          params: {
            id: this.urlParams.id
          }
        })
      }
      if (type?.action === 'create') {
        this.$router.push({
          name: 'suppliersAdd'
        })
      }
      if (type?.action === 'edit') {
        this.$router.push({
          name: 'suppliersEdit',
          params: {
            id: this.urlParams.id
          }
        })
      }
      if (type?.action === 'destroy') {
        this.deleteClick(this.urlParams.id, deleteOnlineCouponDetails, 'suppliersManagerList')
      }
    },
    handleCusClick (item) {
      Utils.custOperate(item, this)
    }
  }
}
